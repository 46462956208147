import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

import * as S from "./styles";

const Photo = () => (
  <S.Photo>
    <S.Corner topOrBottom="top" leftOrRight="left" />
    <S.Corner topOrBottom="top" leftOrRight="right" />
    <S.Corner topOrBottom="bottom" leftOrRight="right" />
    <S.Corner topOrBottom="bottom" leftOrRight="left" />
    <StaticImage
      src="../../../assets/images/almir.jpg"
      alt="Almir Salimov"
      placeholder="blurred"
      layout="fixed"
      height={120}
      width={120}
    />
    {/* <video
      width="120"
      height="120"
      autoPlay
      controls={false}
      controlsList="nodownload"
      loop
      muted
      poster="https://lh3.googleusercontent.com/Oi8mGX8ET58wfWCzV31Nk_CLygDndhBAA_zbJGEkIEUBPnNDLxQGlBMIqdHuQXHwn1dswFyPBKt8MNAWdlXIAc0bFWBRzMWYzTki0A"
      preload="metadata"
    >
      <source
        src="https://openseauserdata.com/files/a62cab08ff89dcef98c3d6adf69a6d64.mp4#t=0.001"
        type="video/mp4"
      />
    </video> */}
  </S.Photo>
);

export default Photo;
