import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";

import Arrow from "assets/images/arrow-right.svg";
import LinkedinLogo from "assets/images/linkedin.svg";
import PageLayout from "components/PageLayout";

import { images } from "./images";
import Photo from "./Photo";
import * as S from "./styles";

const Crew = () => {
  const { t } = useTranslation();

  const projects = [
    {
      id: "wowswap",
      title: "WOWswap",
      text: t("Decentralized leveraged trading protocol providing the best token rates."),
      button: t("View the project"),
      link: "https://wowswap.io/swap"
    },
    {
      id: "pro",
      title: "WOWswap PRO",
      text: t(
        "More trading data, always on chart and possibility to open a position and follow the portfolio in one page."
      ),
      button: t("View the project"),
      link: "https://wowswap.io/pro"
    },
    {
      id: "dao",
      title: t("DAO"),
      text: t("A governance tool that enables WOW stakers to vote for key protocol parameters."),
      button: t("View the project"),
      link: "https://wowswap.io/governance-your-wallet"
    },
    {
      id: "referral",
      title: t("Unique referral program"),
      text: t(
        "Get your super power by becoming a WOWswap X-Degen: Join, promote the DAO and get rewards."
      ),
      button: t("View the project"),
      link: "https://wowswap.io/ambassador"
    },
    {
      id: "wowmax",
      title: "WOWmax",
      text: t(
        "New generation DEX aggregation protocol that uses slippage as an additional source of optimization."
      ),
      button: t("Demo Version"),
      readyStage: t("Under development"),
      link: "https://wowmax.exchange/alpha"
    },
    {
      id: "marketplace",
      title: t("Marketplace"),
      text: t("Decentralized leveraged trading protocol providing the best token rates."),
      readyStage: t("Planned")
    }
  ];

  return (
    <PageLayout>
      <S.Body>
        <div>
          <S.Crew>
            <S.Title>{t("Team of 20 experts")}</S.Title>
          </S.Crew>

          <S.About>
            {t(
              "WOWmax team consists of 20 people, including senior solidity, rust and react developers, marketing & technical specialists and DeFi researchers"
            )}
          </S.About>
        </div>

        <S.Personalities>
          <S.FounderCard>
            <Photo />

            <S.FounderDescription>
              <S.Nickname>{t("Almir Salimov")}</S.Nickname>
              <S.Tags>
                <S.Tag>{t("Founder & CEO")}</S.Tag>
                <a
                  href={"https://www.linkedin.com/in/almirsalimov"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <S.Tag>
                    <LinkedinLogo />
                    <S.TagLinkText>LinkedIn</S.TagLinkText>
                  </S.Tag>
                </a>
              </S.Tags>
              <S.Text>
                {t(
                  "Joined crypto in 2017 and became a DeFi researcher and investor early in 2019."
                )}
              </S.Text>
              <S.Text>
                {t(
                  "Previous experience includes 18 years of corporate and entrepreneurship, including a variety of business development, consulting and executive roles at BP, Merrill Lynch, Booz&Co and several startups."
                )}
              </S.Text>
              <S.Text>
                {t(
                  "Almir Salimov holds MSc with Distinction in Information Systems from London School of Economics and Political Science and MBA from INSEAD."
                )}
              </S.Text>
            </S.FounderDescription>
          </S.FounderCard>

          <S.FounderCardMobile>
            <S.Row>
              <Photo />

              <S.Column>
                <S.Nickname>{t("Almir Salimov")}</S.Nickname>
                <S.Tag>{t("Founder & CEO")}</S.Tag>
                <S.Text>
                  {t(
                    "Joined crypto in 2017 and became a DeFi researcher and investor early in 2019."
                  )}
                </S.Text>
              </S.Column>
            </S.Row>

            <S.Text>
              {t(
                "Previous experience includes 18 years of corporate and entrepreneurship, including a variety of business development, consulting and executive roles at BP, Merrill Lynch, Booz&Co and several startups."
              )}
            </S.Text>
            <S.Text>
              {t(
                "Almir Salimov holds MSc with Distinction in Information Systems from London School of Economics and Political Science and MBA from INSEAD."
              )}
            </S.Text>
          </S.FounderCardMobile>

          <S.ManagersCard>
            <S.CardTitle>10 {t("Managers")}</S.CardTitle>

            <S.CardBody>
              {[
                { label: t("Co-founder"), total: 1 },
                { label: t("Marketing"), total: 3 },
                { label: t("HR"), total: 1 },
                { label: t("Listing Dir"), total: 1 },
                { label: t("Designer"), total: 1 },
                { label: t("IR manager"), total: 1 },
                { label: t("Community (worker)"), total: 2 }
              ].map(({ label, total }) => {
                return (
                  <S.Item key={label}>
                    <S.Label>{label}</S.Label>
                    <S.Count>{total}</S.Count>
                  </S.Item>
                );
              })}
            </S.CardBody>
          </S.ManagersCard>

          <S.DevelopersCard>
            <S.CardTitle>10 {t("Developers")}</S.CardTitle>

            <S.CardBody>
              {[
                { label: t("CTO"), total: 1 },
                { label: t("Rust devs"), total: 2 },
                { label: t("Solidity devs"), total: 2 },
                { label: t("React devs"), total: 2 },
                { label: t("QA"), total: 1 },
                { label: t("Backend dev"), total: 1 },
                { label: t("DevOps"), total: 1 }
              ].map(({ label, total }) => {
                return (
                  <S.Item key={label}>
                    <S.Label>{label}</S.Label>
                    <S.Count>{total}</S.Count>
                  </S.Item>
                );
              })}
            </S.CardBody>
          </S.DevelopersCard>
        </S.Personalities>

        {/* <S.PeopleSection>
        <S.PeopleList>
          {new Array(5).fill(null).map((_, i) => (
            <S.Person key={i}>
              <S.PersonImage>
                {images.people[(i + 1) as keyof typeof images.people]}
              </S.PersonImage>
              <S.PersonName>Vitalii Ivanov</S.PersonName>
              <S.PersonJob>Chief Executive Officer</S.PersonJob>
              <S.PersonText>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </S.PersonText>
              <S.PersonLink>
                Linkedin
                <Arrow />
              </S.PersonLink>
            </S.Person>
          ))}
        </S.PeopleList>
      </S.PeopleSection> */}

        <S.ProjectsSection>
          <S.ProjectsTitle>{t("Our projects")}</S.ProjectsTitle>
          <S.ProjectsText>{t("CTO")}</S.ProjectsText>
          <S.Projects>
            {projects.map(({ id, title, text, link, button, readyStage }, i) => (
              <S.Project key={id} full isNotReady={!!readyStage}>
                <S.ProjectContainer>
                  <S.ProjectTitle>{title}</S.ProjectTitle>
                  <S.ProjectText>{text}</S.ProjectText>
                  {button && (
                    <S.ProjectLink href={link} target="_blank">
                      {button}
                      <Arrow />
                    </S.ProjectLink>
                  )}
                  <S.ProjectImage index={i}>
                    {readyStage ? (
                      <S.ProjectStage>{readyStage}</S.ProjectStage>
                    ) : (
                      images.projects[id as keyof typeof images.projects]
                    )}
                  </S.ProjectImage>
                </S.ProjectContainer>
              </S.Project>
            ))}
          </S.Projects>
        </S.ProjectsSection>
      </S.Body>
    </PageLayout>
  );
};

export default Crew;
