import * as React from "react";

import * as S from "./styles";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const InfoCard = ({ children, className }: Props) => {
  return <S.Root className={className}>{children}</S.Root>;
};

export default InfoCard;
