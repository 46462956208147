import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import Crew from "../containers/Crew";
import pages from "./pages.json";

const CrewPage = () => {
  return (
    <>
      <Helmet>
        <title>{pages.crew.title}</title>
      </Helmet>

      <Layout>
        <Crew />
      </Layout>
    </>
  );
};

export default CrewPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
