import styled, { css } from "styled-components";

export const Photo = styled.div`
  padding: 7px;
  position: relative;
  display: flex;

  background: linear-gradient(
    282.57deg,
    rgba(190, 5, 255, 0.2) -5.58%,
    rgba(7, 76, 255, 0.2) 99.41%
  );
  border: 1px solid #2d4279;
`;

export const Corner = styled.div<{
  position?: ("left" | "right" | "top" | "bottom")[];
  topOrBottom: "top" | "bottom";
  leftOrRight: "left" | "right";
}>`
  height: 10px;
  width: 10px;
  position: absolute;

  ${({ topOrBottom, leftOrRight }) => css`
    border-${topOrBottom}: solid 2.5px white;
    border-${leftOrRight}: solid 2.5px white;
    ${topOrBottom}: 0px;
    ${leftOrRight}: 0px;
  `}
`;
