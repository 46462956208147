import styled, { css } from "styled-components";

import Card from "components/Card";
import InfoCard from "components/InfoCard";

export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  grid-row-gap: 40px;

  @media (max-width: 768px) {
    /* padding-bottom: 200px; */
    grid-row-gap: 20px;
  }
`;

export const Crew = styled.div``;

export const Title = styled.h1`
  padding: 0;
  margin: 0;
  font-size: 88px;
  line-height: 93px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 50px;
    line-height: 53px;
  }
`;

export const About = styled.p`
  padding: 0;
  margin: 0 auto;
  max-width: 600px;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;

  animation-name: blurin;
  animation-duration: 2s;

  @keyframes blurin {
    0% {
      filter: blur(10px);
    }
    100% {
      filter: blur(0px);
    }
  }
`;

export const Personalities = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;

export const FounderCard = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  grid-column-gap: 20px;
  max-width: 754px;
  min-width: 380px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const FounderCardMobile = styled(Card)`
  display: none;

  @media (max-width: 768px) {
    padding: 20px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 12px;
    max-width: 360px;
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  grid-column-gap: 12px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  grid-row-gap: 12px;
`;

export const FounderDescription = styled.section`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  grid-row-gap: 12px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Nickname = styled.h3`
  font-weight: bold;
  padding: 0;
  margin: 0;
  font-size: 30px;
  line-height: 32px;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 26px;
  }
`;

export const Tags = styled.div`
  display: flex;

  > * {
    margin-right: 10px;
  }
`;

export const Tag = styled.div`
  height: 26px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  background: linear-gradient(
    282.57deg,
    rgba(190, 5, 255, 0.3) -5.58%,
    rgba(7, 76, 255, 0.3) 99.41%
  );
  font-size: 15px;
  line-height: 16px;
  color: #ffffff;

  svg {
    margin-left: -8px;

    path {
      fill: #90a3de;
    }
  }
`;

export const TagLinkText = styled.div`
  margin-left: 5px;
  color: #ffffff;
  text-decoration: underline;
`;

export const Text = styled.p`
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 140%;
  color: #90a3de;
`;

const CrewCard = styled(InfoCard)`
  padding: 35px 30px 20px 20px;
  grid-row-gap: 10px;
  max-width: 230px;
  min-width: 190px;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 360px;
    padding: 20px 30px 20px 20px;
  }

  animation-name: anim;
  animation-duration: 0.7s;

  @keyframes anim {
    0% {
      clip-path: circle(0% at 150% 150%);
    }
    100% {
      clip-path: circle(150%);
    }
  }
`;

export const ManagersCard = styled(CrewCard)``;

export const DevelopersCard = styled(CrewCard)``;

export const CardTitle = styled.h3`
  font-weight: bold;
  padding: 0;
  margin: 0;
  font-size: 22px;
  line-height: 24px;
  color: #ffffff;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 6px;
  font-size: 15px;
  line-height: 140%;
  color: #ffffff;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.div`
  margin-right: 2px;
`;

export const Count = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;

  background-image: url("/images/circle.svg");
`;

export const PeopleSection = styled.div`
  width: 100%;
  margin-bottom: 90px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

export const PeopleList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1000px) {
    overflow-y: scroll;
  }
`;

export const Person = styled.div`
  max-width: 240px;
  padding: 10px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
`;

export const PersonImage = styled.div``;

export const PersonName = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

export const PersonJob = styled.div`
  width: 100%;
  max-width: 180px;
  min-height: 32px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #054bff 0%, #be05ff 100%);
  border-radius: 10px;
  font-size: 15px;
  font-weight: bold;
`;

export const PersonText = styled.div`
  text-align: center;
  font-size: 15px;
  line-height: 1.5;
  color: #90a3de;
`;

const Link = styled.a`
  padding: 10px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2d4279;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  cursor: pointer;

  svg path {
    stroke: white;
  }

  svg {
    margin-left: 10px;
  }

  &:hover {
    background: linear-gradient(90deg, rgba(5, 75, 255, 0.2) 0%, rgba(190, 5, 255, 0.2) 100%);
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #054bff, #be05ff);
  }
`;

export const PersonLink = styled(Link)``;

export const ProjectsSection = styled.div``;

export const ProjectsTitle = styled.h2`
  margin: 0px 0px 3px;
  font-size: 64px;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 42px;
  }
`;

export const ProjectsText = styled.div`
  margin-bottom: 25px;
  font-size: 18px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 20px;
    font-size: 15px;
  }
`;

export const Projects = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

export const ProjectTitle = styled.div`
  grid-area: title;
  margin: 20px 3px 8px 20px;
  font-size: 22px;
  font-weight: bold;
`;

export const Project = styled(Card)<{ isNotReady: boolean }>`
  width: 100%;
  max-width: 400px;
  padding: 0px;

  ${(props) =>
    props.isNotReady &&
    css`
      ${ProjectTitle} {
        color: #90a3de;
      }
    `}

  @media (max-width: 768px) {
    grid-template-areas:
      "title image"
      "text  image"
      "link  link";
  }
`;

export const ProjectContainer = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-areas:
    "title image"
    "text  image"
    "link  image";

  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr auto;
  gap: 0px;
`;

export const ProjectText = styled.div`
  grid-area: text;
  margin: 0px 3px 10px 20px;
  font-size: 15px;
  line-height: 1.4;
  color: #90a3de;
`;

export const ProjectLink = styled(Link)`
  grid-area: link;
  margin: auto 0px 20px 20px;
  width: max-content;

  @media (max-width: 768px) {
    width: auto;
    margin: 10px 20px 20px 20px;
  }
`;

export const ProjectImage = styled.div<{ index: number }>`
  grid-area: image;
  width: 194px;
  height: 234px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${(props) => (props.index % 2 === 0 ? "#be05ff" : "#054BFF")};
    opacity: 0.8;
    filter: blur(100px);
  }
`;

export const ProjectStage = styled.div`
  max-width: 132px;
  padding: 9px 16px;
  border-radius: 10px;
  background: linear-gradient(90deg, #054bff 0%, #be05ff 100%);
  transform: rotate(-18deg);
  text-align: center;
  font-size: 18px;
  font-weight: bold;
`;
