import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const people = {
  1: (
    <StaticImage
      src="../../assets/images/people/1.png"
      alt="Worker"
      placeholder="blurred"
      layout="fixed"
      height={150}
    />
  ),
  2: (
    <StaticImage
      src="../../assets/images/people/2.png"
      alt="Worker"
      placeholder="blurred"
      layout="fixed"
      height={150}
    />
  ),
  3: (
    <StaticImage
      src="../../assets/images/people/3.png"
      alt="Worker"
      placeholder="blurred"
      layout="fixed"
      height={150}
    />
  ),
  4: (
    <StaticImage
      src="../../assets/images/people/4.png"
      alt="Worker"
      placeholder="blurred"
      layout="fixed"
      height={150}
    />
  ),
  5: (
    <StaticImage
      src="../../assets/images/people/5.png"
      alt="Worker"
      placeholder="blurred"
      layout="fixed"
      height={150}
    />
  )
} as const;

const projects = {
  wowswap: (
    <StaticImage
      src="../../assets/images/projects/wowswap.png"
      alt="Wowswap"
      placeholder="blurred"
      layout="fixed"
      height={234}
    />
  ),
  pro: (
    <StaticImage
      src="../../assets/images/projects/pro.png"
      alt="Wowswap PRO"
      placeholder="blurred"
      layout="fixed"
      height={234}
    />
  ),
  dao: (
    <StaticImage
      src="../../assets/images/projects/dao.png"
      alt="Wowswap DAO"
      placeholder="blurred"
      layout="fixed"
      height={234}
    />
  ),
  referral: (
    <StaticImage
      src="../../assets/images/projects/referral.png"
      alt="Referral program"
      placeholder="blurred"
      layout="fixed"
      height={234}
    />
  )
};

export const images = {
  people,
  projects
};
