import styled from "styled-components";

export const Root = styled.section`
  padding: 15px 25px 15px 20px;
  display: flex;
  flex-direction: column;
  min-width: 290px;
  max-width: 370px;

  font-size: 15px;
  line-height: 140%;
  color: #ffffff;
  background: linear-gradient(
    291.26deg,
    rgba(190, 5, 255, 0.1) 26.14%,
    rgba(7, 76, 255, 0.1) 99.58%
  );

  @media (max-width: 1020px) {
    background: linear-gradient(
      293.89deg,
      rgba(190, 5, 255, 0.1) 19.05%,
      rgba(7, 76, 255, 0.1) 99.54%
    );
  }
`;
